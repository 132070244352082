/**
 * Front
**/

/**
 * Elevator pitch
**/
.elevator-pitch{
    padding-bottom: 0;
    margin-bottom: 25px;

    @include media-breakpoint-up(md){
        margin-bottom: 0;
    }

    h1{
        opacity:0;
        transform: translateY(35px);

        animation-name: ep-reveal-in;
        animation-timing-function: cubic-bezier(0.53, 0.01, 0.18, 1);
        animation-fill-mode: forwards;
        animation-duration: 1.3s;
        animation-delay: 1s;  
    }

    p{
        opacity: 0;

        animation-name: ep-reveal-in;
        animation-timing-function: cubic-bezier(0.53, 0.01, 0.18, 1);
        animation-fill-mode: forwards;
        animation-duration: 1.5s;
        animation-delay: 1.2s;           
    }

    p:not(.btn-wrap){
        font-weight: 300;
        max-width: 850px;
        margin: 0 auto;
        margin-top: 20px;
       
        @include media-breakpoint-up(lg){
            font-size: 24px;
        }
    }

    .btn-wrap{
        margin-top: 50px;
    }
}

@keyframes ep-reveal-in{
    from {opacity: 0;}
    to {opacity: 1; transform: translateY(0px);}  
}


/**
 * Sociale Maribel
**/
.sociale-maribel{
    background-image: url('images/bg-sociale-maribel.png');
    background-attachment: fixed;
    background-size: cover;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;

    &:after{
        content: '';
        position: absolute;
        background-image: url('images/intersect.svg');
        background-size: 100%;
        width: 330px;
        height: 240px;
        right: 50px;
        bottom: 0;
    }

    h2,
    h3,
    p,
    li,
    a{
        color: $white;
    }

    h3{
        margin-bottom: 40px;        
        
        @include media-breakpoint-up(lg){
            font-size: 48px;
        }
    }

    p,
    li{
        @include media-breakpoint-up(lg){
            font-size: 18px;
            line-height: 34px;     
        }
    }

    .btn{
        font-size: 16px;
        line-height: 24px;
        margin-top: 25px;
    }
}

/**
 * Call to action
**/
.call-to-action{
    h3{
        @include media-breakpoint-up(lg){
            font-size: 48px;
            margin-bottom: 40px;
        }
    }
}