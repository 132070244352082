/**
 * Header
**/

.intro-header{
    h1{
        margin-bottom: 25px;
    }

    .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }
    
    &__image{
        img{
            object-fit: cover;
            height: 100%;
        }
    }

    &__content{
        padding: 75px 15px;
        position: relative;

        @include media-breakpoint-up(md){
            padding: 75px 30px;
        }

        @include media-breakpoint-up(lg){
            padding: 75px;
        }        

        &:after{
            content: '';
            position: absolute;
            background-image: url('images/intersect.svg');
            background-size: 100%;
            opacity: .25;
            width: 330px;
            height: 240px;
            right: 50px;
            bottom: 0;
        }    

        h2,
        ul,
        li,
        p,
        a{
            color: $white;
            max-width: 550px;
        }

        p,
        ul,
        li,
        a{
            line-height: 30px;
        }

        h2{
            @include media-breakpoint-up(lg){
                font-size: 38px;
                margin-bottom: 50px;
            }
        }

        .sector-title{
            font-size: 24px;
            font-weight: 700;             
        }
    }

    .sector-319_01{
        .sector-title{
            color: $red !important;
        }
    }  
    
    .sector-329_01{
        .sector-title{
            color: $pink !important;
        }
    }

    .sector-331{
        .sector-title{
            color: $blue-light !important;
        }
    }       
}