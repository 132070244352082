.row:before, .row:after {
	display: none !important;
}

html{
	overflow-x: hidden;
}

.row:before, .row:after {
	display: none !important;
}

.post-content-exists{
	padding-top: 0px !important;
	margin-top: -50px;
}

.google-map{
	height: 450px;
}