/**
 * Sectoren
**/
.sectoren{
    &--sociale-maribel{
        @extend .bg--blue-light-10;
        
        margin-bottom: 75px;
        margin-top: 75px;

        h3{
            @include media-breakpoint-up(lg){
                font-size: 40px;
                margin-bottom: 30px;
            }
        }
    }

    .row .col-6{
        &:nth-child(1){
            .sector{
                animation-delay: 1.4s;
            }
        } 
        
        &:nth-child(2){
            .sector{
                animation-delay: 1.6s;
            }
        } 
    
        &:nth-child(3){
            .sector{
                animation-delay: 1.8s;
            }
        } 
    
        &:nth-child(4){
            .sector{
                animation-delay: 2s;
            }
        }

        &:nth-child(5){
            .sector{
                animation-delay: 2.2s;
            }
        }   
    }

}

.sector{
    @include media-breakpoint-down(md){
        margin-bottom: 25px;
    }

    opacity: 0;
    transform: translateY(25px);

    animation-name: sector-reveal-in;
    animation-timing-function: cubic-bezier(0.53, 0.01, 0.18, 1);
    animation-fill-mode: forwards;
    animation-duration: 0.8s;
    animation-delay: 2s;   

    &:hover{
        img{
            transform: scale(1.1);
        }    
    }

    a{
        text-decoration: none;
    }

    img{
        transition: all 0.7s cubic-bezier(0.53, 0.01, 0.18, 1);
    }

    h2{
        font-size: 28px; 
        font-weight: 700;
        text-align: center;
        
        span{
            display: block;
            font-size: 22px;
        }

        @include media-breakpoint-up(lg){
            font-size: 36px;

            span{
                font-size: 30px;
            }
        }
    }

    &--sector-318-02{
        .sector-content-wrapper{
            @extend .bg--blue;
        }

        h2{
            color: $primary-blue;
        }
    }

    &--sector-319-01{
        .sector-content-wrapper{
            @extend .bg--yellow;
        }

        h2{
            color: $red;
        }
    }   
    
    &--sector-329-01{
        .sector-content-wrapper{        
            @extend .bg--pink;
        }

        h2{
            color: $wine;
        }
    }        

    &--sector-331{
        .sector-content-wrapper{
            @extend .bg--blue-light;
        }

        h2{
            color: $olive;
        }
    }

}

@keyframes sector-reveal-in{
    from {opacity: 0;}
    to {opacity: 1; transform: translateY(0px);}  
}