/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Fonts
**/
$heading-font: bruna, sans-serif; 
$body-font: open-sans, sans-serif;

/**
 * Colors
 **/

$primary-color: #FFFFFF;
$secondary-color: #FFFFFF;

$white: #FFFFFF;
$black: #000000;

$primary-yellow: #FAB60F;
$primary-blue: #1A374C;

$wine: #82293F;
$pink: #FFC5B9;
$yellow: #FFC763;
$red: #E84C4D;
$olive: #758C20;
$green-dark :#16585E;
$blue-light: #B5E3F5;
$blue: #6DA5F9;
