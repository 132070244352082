/**
 * Extranet
**/

.extranet{

    .container{
        position: relative;

        @include media-breakpoint-up(md){
            &:before{
                content:'';
                border-radius: 10px;
                position: absolute;
                background-image: url('images/extranet.png');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 350px;
                height: 297px;
                left: 15px;
                top: calc(50% - 175px);
                z-index: 10;
            }
        }

        @include media-breakpoint-up(lg){
            &:before{
                width: 410px;
                height: 350px;                
            }
        }
    }

    &__content-wrapper{
        position: relative;

        &:after{
            content: '';
            position: absolute;
            background-image: url('images/intersect.svg');
            background-size: 100%;
            width: 330px;
            height: 240px;
            right: 50px;
            bottom: 0;
        }           
    }

    &__content{
        margin-left: 30px;
        margin-right: 30px; 
        position: relative;
        z-index: 11;

        @include media-breakpoint-up(md){
            margin-left: 290px;
            margin-right: 45px;
        }

        @include media-breakpoint-up(lg){
            margin-left: 310px;
            margin-right: 65px;             
        }

        @include media-breakpoint-up(xl){
            margin-left: 260px;
            margin-right: 155px;            
        }
    }

    h3{
        margin-bottom: 20px;
    }    

    .btn{
        margin-top: 30px;
    }
}