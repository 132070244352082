/**
 * Utils
**/

/**
 * Backgrounds
 **/
 @each $name, $color in (
    'primary-yellow': $primary-yellow,
    'primary-blue': $primary-blue,
    'wine': $wine,
    'pink': $pink,
    'yellow': $yellow,
    'yellow-25': rgba($yellow, .25),
    'red': $red,
    'olive': $olive,
    'green-dark': $green-dark,
    'blue-light': $blue-light,
    'blue-light-10': rgba($blue-light, .1),
    'blue': $blue,
    'white': $white,
    'black': $black,
    ) {
	.bg--#{$name} {
		background-color: $color;
  	}

    .t--#{$name} {
	    color: $color !important;
  	}    
}

/**
 * Blocks
**/
@each $name, $size in ('10': 10, '20': 20, '25': 25, '35': 35, '50': 50, '75': 75, '100': 100, '120': 120, '150': 150) {
	$mobile-size: calc($size/2);
    
    .block-#{$name} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
  	}

    @include media-breakpoint-down(md){
        .block-#{$name} {
            padding-top: #{$mobile-size}px;
            padding-bottom: #{$mobile-size}px;
          }        
    }
}

.block-120{
    @include media-breakpoint-down(xl){
        padding-bottom: 60px;
        padding-top: 60px;
    }
}

/**
 * Border
**/
.border-10{
    border-radius: 10px;    
    overflow: hidden;
}

.border-t-10{
    border-radius: 10px 10px 0px 0px;   
    overflow: hidden;
}

.border-b-10{
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

/**
 * Class
**/
.w-100{
    width: 100%;
}

.flex{
    display: flex;
    align-items: center;	
}

.align-center{
	align-content: center;
}