/**
 * Accordion - Bootstrap 5 overrides
**/

.accordion-button{
    color: $primary-blue;
    font-size: 24px;
    padding-left: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(md){
        font-size: 38px;        
    }
}

.accordion-flush .accordion-item{
    border-bottom: 1px solid $primary-blue;
    padding-bottom: 15px;
    padding-top: 10px;    
}

.accordion-button:not(.collapsed){
    background-color: transparent;
    box-shadow: none;

    color: $primary-blue;
}

.accordion-body{
    padding-left: 0;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    background-position: center;
    background-color: $primary-blue;
    border-radius: 100px;
    padding: 15px;
}

.accordion-header{
    opacity: 0;
    transform: translateY(25px);
}