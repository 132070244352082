/**
 * Footer
**/

footer#footer{
    margin-top: 50px;

    h5{
        font-size: 20px;
        margin-top: 30px;

        @include media-breakpoint-up(md){
            margin-top: 0;
        }
    }

    ul{
        padding-left: 0;
        list-style-type: none;

        a{
            color: $primary-blue;
            font-size: 14px;
            text-decoration: underline;
            line-height: 24px;

            text-decoration-color: $white;
            text-decoration-thickness: 1px;
            text-underline-offset: 3px;    
        
            transition: all 0.3s cubic-bezier(0.53, 0.01, 0.18, 1);
        
            &:hover{
                color: $primary-yellow;
                text-decoration-color: $yellow;
                text-underline-offset: 1px;    
            }    
        }
    }

    p{
        font-size: 14px;
        line-height: 24px;
        
        a{
            color: $primary-blue;
        }
    }

    @include media-breakpoint-up(lg){
        .col-md-2,
        .col-md-3{
            position: relative;
            left: -15px;        
        }
    }
}

.footer-row{
    border-bottom: 1px solid $primary-blue;
    border-top: 1px solid $primary-blue;
    padding-top: 60px;
    padding-bottom: 30px;    
}

.footer-logo{
    img{
        margin-top: -15px;
        margin-left: -24px;
        max-width: 100%;

        @include media-breakpoint-up(md){
            margin-left: 0;
        }
    }
    
    p{
        margin-top: -6px;

        @include media-breakpoint-up(md){
            margin-left: 24px;             
        }
    }
}

.contact-widget{
    @include media-breakpoint-only(md){
        h5{
            margin-top: 40px;
        }
    }
}

.footer-final{
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 40px;

    a{
        color: $primary-blue;
        font-size: 12px;
        text-decoration: none;
        line-height: 14px;        
    }
}