/**
 * Typography
**/

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}  

body{
    font-family: $body-font;
    color: $primary-blue;
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-family: $heading-font;
}

h1{
    @include media-breakpoint-up(lg){
        font-size: 48px;
    }
}

h2,
h3{
    @include media-breakpoint-up(lg){
        font-size: 48px;
    }
}

.article-body,
.accordion-body,
footer p{
    a{
        color: $primary-blue;
        text-decoration-thickness: 1px;
        text-underline-offset: 1px;    
        transition:  all .3s ease;

        &:hover{
            text-decoration-color: $primary-yellow;
        }
    }
}

/**
 * Typo class
**/

.text-center{
    text-align: center !important;
}

.text-right{
    text-align: right;
}

.font-bold{
    font-weight: 700; 
}

/**
 * Buttons
**/
.btn{
    border-radius: 100px;
    display: inline-block;
    padding: 10px 25px;
    text-decoration: none;
    transition: all 0.45s cubic-bezier(0.53, 0.01, 0.18, 1);

    @include media-breakpoint-up(lg){
        padding: 10px 45px;
    }
    
    &--primary{
        border: 1.5px solid $primary-blue;
        color: $primary-blue;       

        &:hover{
            background-color: $primary-blue;
            color: $white;
        }
    }
    
    &--primary-white{
        border: 1.5px solid $white;
        color: $white;       

        &:hover{
            background: $white;
            color: $primary-blue;
        }
    }
}