/**
 * Navbar
**/

#menu-entries{
	flex-direction: row-reverse;	
}

.navbar-top{
	height: 40px;

	ul{
		float: right;
	}

	li{
		font-size: 16px; 
		display: inline;

		a{
			color: $white;
			font-size: 13px;
			transition: all 0.3s cubic-bezier(0.53, 0.01, 0.18, 1);
			
			&:hover{
				color: $primary-yellow;
			}

			@include media-breakpoint-up(md){
				font-size: 16px;
			}
		}
	}
}

@include media-breakpoint-down(md){
	.navbar-brand{
		img{
			max-width: 250px;		
		}
	}
}

#nav-main{
	padding-top: 40px;
	padding-bottom: 25px;

	.nav-link{
		color: $primary-blue;
		font-size: 16px;
		margin-right: 5px;

		text-decoration: underline;
		text-decoration-color: $white;
		text-decoration-thickness: 1.5px;
		text-underline-offset: 7px;    
	
		transition: all 0.3s cubic-bezier(0.53, 0.01, 0.18, 1); 			

		@include media-breakpoint-up(xxl){
			margin-right: 20px;
		}
	}

	.nav-item:not(.current-menu-item){
		.nav-link{
			&:hover{
				color: $primary-yellow;
				text-decoration-color: $yellow;
				text-underline-offset: 5px;    
			}   
		}
	}

	.current-menu-item{
		.nav-link{
			font-weight: 700;
		}
	}

	.btn-wrap{
		.btn{
			padding: 6px 25px !important;	
		}
	}
}

.line-bottom{
	border-bottom: 2px solid $primary-blue;	
}


/**
 * Mobile only
 **/

.navbar-expand-lg .navbar-toggler{
	display: inherit !important;

	@include media-breakpoint-up(xl){
		display: none !important;
	}
}


@include media-breakpoint-down(xl) {
	#nav-main{
        flex-wrap: wrap;
	}

	#menu-entries{
		display: none !important;

		.navbar-nav{
			padding-top: 25px;
			border-top: 2px solid $primary-blue;
			margin-top: 17px;			
		}
	
		&.show{
			display: block !important;
			width: 100% !important;
		}

		.navbar-nav{
			flex-direction: column;			
		}

		ul{
			width: 100%;
		}

		.nav-link{
			padding-bottom: 15px;			
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		float: right;
	}
}

#menu-entries{
	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}

.navbar-toggler{
	border: 0;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(26, 55, 76, 1.0)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}