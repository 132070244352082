/**
 * News item
**/
.news-filters{
    margin-bottom: 60px;
    margin-top: -50px;

    span,
    a{
        font-size: 16px;        
    }

    span{
        text-transform: uppercase;
        display: inline;
        margin-right: 40px;
        position: relative;

        &:after{
            content: '';
            position: absolute;
            width: 20px;
            height: 1.5px;
            background-color: $primary-blue;
            top: 12px;
            left: 55px;            
        }
    } 

    ul{
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        padding-bottom: 0;
    }

    li{
        display: inline;
        margin-right: 25px;

        a{
            color: $primary-blue;

            text-decoration: underline;
            text-decoration-color: $white;
            text-decoration-thickness: 1.5px;
            text-underline-offset: 7px;    
        
            transition: all 0.3s cubic-bezier(0.53, 0.01, 0.18, 1);            

            &:hover{
                color: $primary-yellow;
				text-decoration-color: $yellow;
				text-underline-offset: 5px;                    
            }
        }
    }
}

.news{
    &__title{
        p{
            width: 100%;
            text-align: right;   
            margin-bottom: 0;          

            a{
                color: $primary-blue;
                text-decoration-thickness: 1.5px;
                text-underline-offset: 1px;    
                
                transition: all 0.3s cubic-bezier(0.53, 0.01, 0.18, 1);                        

                &:hover{
                    text-decoration-color: $primary-yellow;
                }
            }
        }
    }
}

.news-item{
    margin-bottom: 50px;

    &:hover{
        img{
            transform: scale(1.05);
        }    
    }

    @include media-breakpoint-up(md){
        margin-bottom: 0;
    }

    a{
        text-decoration: none;
    }

    img{
        transition: all 0.7s cubic-bezier(0.53, 0.01, 0.18, 1);
    }

    &__category{
        color: $yellow;
        display: block;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        margin-top: 35px;       
    }

    h3{
        color: $primary-blue;
        font-size: 20px; 
        line-height: 28px;
        margin-top: 10px;
    }

    &--with-filter{
        display: none;

        &.reveal{
            display: inherit;
        }
    }    
}