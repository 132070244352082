/**
 * Call to action
 **/
.call-to-action{
    
    &__content{
        padding-bottom: 50px;

        background-image: url('images/intersect.svg');
        background-repeat: no-repeat;
        background-position: bottom left 50px;
    }

    @include media-breakpoint-down(lg){
        h3{
            margin-bottom: 25px;
        }
    }
}